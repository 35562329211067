@font-face {
  font-family: xmas;
  src: url(./DotGothic-Regular.e3fdda2f.ttf);
}
body{
  background-image: url("https://i.pinimg.com/originals/ea/aa/3e/eaaa3e291bb74423a67a9ce2875ced3f.jpg");
background-size: cover;
/* background-repeat: no-repeat; */
background-position: center bottom;
background-attachment: fixed;

}
.App {
  text-align: center;


}
body{


}
.topcorner{
  position:absolute;
  top:10px;
  right: 10px;

  font-weight: bolder;
}
.wallet-adapter-button{
  /* background-image: linear-gradient(to right, #740082, #d1006d, #ff5b49, #ffaf21, #f2ff43); */
  background-color: #378b29;
background-image: linear-gradient(315deg, #378b29 0%, #74d680 74%);
  color: white;
  border-radius: 10px !important;
  box-shadow: 10px 10px 5px rgb(8, 8, 8);
  font-family: xmas  !important;

}
.center{
  position: absolute; 
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  
}
img{
  width: 50%;
  height: 50%;
  border-radius: 10px;
  box-shadow: 10px 10px 5px rgb(8, 8, 8);
}
.btn {
  font-family: xmas  !important;
  border-radius: 10px;
  font-size: 1.2vw;
  padding: 0.9rem;
  background-color: #378b29;
  background-image: linear-gradient(315deg, #378b29 0%, #74d680 74%);
  color: white;
  font-weight: bolder;
  cursor: pointer;
  margin-top: 1.3rem;
  box-shadow: 10px 10px 5px rgb(8, 8, 8);
}
.btn:active {
  background-color: #3e8e41;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}
@media screen and (max-width:1024px)  {
  img{
    width: 100%;
    height: 100%;
    border-radius: 30px;
  }
  .btn {
 
    font-size: 5.2vw;
  }
}


.snowflake {
  color: #fff;
  font-size: 1em;
  font-family: Arial;
  text-shadow: 0 0 1px #000;
}

@-webkit-keyframes snowflakes-fall{0%{top:-10%}100%{top:100%}}@-webkit-keyframes snowflakes-shake{0%{-webkit-transform:translateX(0px);transform:translateX(0px)}50%{-webkit-transform:translateX(80px);transform:translateX(80px)}100%{-webkit-transform:translateX(0px);transform:translateX(0px)}}@keyframes snowflakes-fall{0%{top:-10%}100%{top:100%}}@keyframes snowflakes-shake{0%{transform:translateX(0px)}50%{transform:translateX(80px)}100%{transform:translateX(0px)}}.snowflake{position:fixed;top:-10%;z-index:9999;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;cursor:default;-webkit-animation-name:snowflakes-fall,snowflakes-shake;-webkit-animation-duration:4s,3s;-webkit-animation-timing-function:linear,ease-in-out;-webkit-animation-iteration-count:infinite,infinite;-webkit-animation-play-state:running,running;animation-name:snowflakes-fall,snowflakes-shake;animation-duration:4s,3s;animation-timing-function:linear,ease-in-out;animation-iteration-count:infinite,infinite;animation-play-state:running,running}.snowflake:nth-of-type(0){left:1%;-webkit-animation-delay:0s,0s;animation-delay:0s,0s}.snowflake:nth-of-type(1){left:10%;-webkit-animation-delay:1s,1s;animation-delay:1s,1s}.snowflake:nth-of-type(2){left:20%;-webkit-animation-delay:6s,.5s;animation-delay:6s,.5s}.snowflake:nth-of-type(3){left:30%;-webkit-animation-delay:4s,2s;animation-delay:4s,2s}.snowflake:nth-of-type(4){left:40%;-webkit-animation-delay:2s,2s;animation-delay:2s,2s}.snowflake:nth-of-type(5){left:50%;-webkit-animation-delay:8s,3s;animation-delay:8s,3s}.snowflake:nth-of-type(6){left:60%;-webkit-animation-delay:6s,2s;animation-delay:6s,2s}.snowflake:nth-of-type(7){left:70%;-webkit-animation-delay:2.5s,1s;animation-delay:2.5s,1s}.snowflake:nth-of-type(8){left:80%;-webkit-animation-delay:1s,0s;animation-delay:1s,0s}.snowflake:nth-of-type(9){left:90%;-webkit-animation-delay:3s,1.5s;animation-delay:3s,1.5s}